@import "../../styling/Colors.scss";
* {
  -webkit-text-size-adjust:none;
-ms-text-size-adjust:none;
-moz-text-size-adjust:none;
text-size-adjust:none;
}
.SideBar {
  position: fixed;
  width: 40px;
  height: 100%;
  background-color: none;
  z-index: 105;
  left:0;
  top:0;
};

.ButtonsContainer {
  position: absolute;
  height: 100%;
  width: 40px;

  .HomeButton {
    display: block;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    margin-top: 30px;
    margin-left: 0;
    -webkit-text-size-adjust:none;
    cursor:pointer;

    .ButtonStyle{
      background: none;
      border: none;
      color: grey;
      padding: 5px;
      font-size: 1.3rem;
      transition-duration: 0.2s;
    }
    .ButtonStyle:hover{
      color: red;
    }
  }
  ul{
    position: absolute;
    height: 64%;
    width: 40px;
    top:20px;
    padding: 0;
    margin:0;
    li {
      display: block;
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      margin-bottom: 10vh;
      margin-left: 0;
      cursor:pointer;
      .ButtonStyle{
        background: none;
        border: none;
        color: grey;
        padding: 5px;
        font-size: 1.3rem;
        transition-duration: 0.2s;
      }
      .ButtonStyle:hover{
        color: red;
      }
    }
  }
}

.MobileMenuButten {
  position: fixed;
  height: 50px;
  width: 50px;
  background: rgba(36, 36, 36, 0.5);
  z-index: 200;
  border-radius: 50% !important;
  bottom: 15px;
  left: -300px;

  div{
    position: relative;
    display: block;
    height: 3px;
    width: 30px;
    background:white;
    border-radius: 20px !important;
    margin:auto;
    margin-top:5px;
    top:11px;
  }
}
.MobileMenuButtenActive {
  position: fixed;
  height: 52px;
  width: 52px;
  background: $Red;
  z-index: 200;
  border-radius: 50% !important;
  bottom: 15px;
  left: 15px;

  div{
    position: relative;
    display: block;
    height: 3px;
    width: 30px;
    background:white;
    border-radius: 20px !important;
    margin:auto;
    margin-top:5.5px;
    top:11px;

  }
}
.MobileMenu {
  position: fixed;
  height: auto;
  width: auto;
  z-index: 200;
  bottom: 70px;
  left: 15px;
  font-size:1.3em;
  list-style: none;
  padding:0;
    li{
      margin-top:12px;
      border-radius: 25px!important;
      div{
      background: rgba(36, 36, 36, 0.5);
      color:white;
      padding:3px;
      padding-left:13px;
      padding-right:13px;

    }
  }
}

.MobileMenuActive {
  position: fixed;
  height: auto;
  width: auto;
  z-index: 105;
  bottom: 70px;
  left: -500px;
  font-size:1.3em;
  list-style: none;
  padding:0;
    li{
      margin-top:12px;
      border-radius: 25px!important;
      div{
      color:white;
      background: rgba(36, 36, 36, 0.5);
      padding:3px;
      padding-left:13px;
      padding-right:13px;
    }
  }
}
@media only screen and (max-width: 800px) {
  .SideBar {
    position: fixed;
    width: 40px;
    height: 100%;
    background-color: none;
    z-index: 105;
    left:-100px;
    top:0;
  };
  .MobileMenuButten {
    left: 15px;
  }

}