@import "../../styling/Colors.scss";


.BackgroundVideo {
    position: relative;
    height: 100%;
    width: 100%;
    background:white;
 video{
    position: relative;
    height: 0%;
    width: 100%;
    background-color: black;
    background: url(../../Images/01.jpg);
    background-size:cover !important;
    background-position: center center!important; 
    background-repeat: no-repeat;
    z-index: 99!important;
    object-fit: cover;
    
 }
};

.BackgroundVideoSection {
    position: relative;
    max-width: 100%; 
    height: auto;

    .Arrows {
        position: absolute;
        height: 300px;
        width: 300px;
        z-index: 105;
        transform: translate(-50%,-50%);
        left:50%;
        bottom:-20%;
        .arrow {
            opacity: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
            -webkit-transform: translate3d(-50%, -50%, 0);
                    transform: translate3d(-50%, -50%, 0);
            }

            .arrow-1 {
            -webkit-animation: arrow-movement 2s ease-in-out infinite;
                    animation: arrow-movement 2s ease-in-out infinite;
            }

            .arrow-2 {
            -webkit-animation: arrow-movement 2s 1s ease-in-out infinite;
                    animation: arrow-movement 2s 1s ease-in-out infinite;
            }

            .arrow:before,
            .arrow:after {
            background: #fff;
            content: '';
            display: block;
            height: 3px;
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            }

            .arrow:before {
            -webkit-transform: rotate(45deg) translateX(-23%);
                    transform: rotate(45deg) translateX(-23%);
            -webkit-transform-origin: top left;
                    transform-origin: top left;
            }

            .arrow:after {
            -webkit-transform: rotate(-45deg) translateX(23%);
                    transform: rotate(-45deg) translateX(23%);
            -webkit-transform-origin: top right;
                    transform-origin: top right;
            }

            @-webkit-keyframes arrow-movement {
            0% {
                opacity: 0;
                top: 45%;
            }
            70% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
            }

            @keyframes arrow-movement {
            0% {
                opacity: 0;
                top: 45%;
            }
            70% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
          }

    }

    .DarkMask{
        position: absolute;
        width: 100%; 
        height: 99.5%;
        background-color: black;
        z-index: 101;
        opacity: 0.5;
    }

    .VideoTitle {
        position: absolute;
        height: 200px;
        width: 700px;
        color: white;
        top: 40%;
        left:15%;
        font-size: 2.4em;
        line-height: 1.6;
        font-family: 'Raleway', sans-serif;
        z-index: 105;
      


        span {
            margin-left: 10px;
            color:$Red;
            font-family: 'Raleway', sans-serif;
    
        }
    
        .Logo {
            position: relative;
            width: 380px;
            height: 70px;
            margin-top:40px;
            left: -4px;
            background-color: red;
            background: url(../../Images/logo.png);
            background-size:contain ;
            background-position: center center; 
            background-repeat: no-repeat;
            z-index: 100;
        }
    }
}

@media only screen and (max-width: 800px) {
    .BackgroundVideoSection {
        height:100vh;
        overflow: hidden;
        .Arrows {
            position: absolute;
            height: 1000px;
            width: 300px;
            z-index: 105;
            transform: translate(-50%,-50%);
            left:50%;
            bottom:-20%;
            opacity: 0;
            .arrow {
                opacity: 0;
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform-origin: 50% 50%;
                        transform-origin: 50% 50%;
                -webkit-transform: translate3d(-50%, -50%, 0);
                        transform: translate3d(-50%, -50%, 0);
                }
    
                .arrow-1 {
                -webkit-animation: arrow-movement 2s ease-in-out infinite;
                        animation: arrow-movement 2s ease-in-out infinite;
                }
    
                .arrow-2 {
                -webkit-animation: arrow-movement 2s 1s ease-in-out infinite;
                        animation: arrow-movement 2s 1s ease-in-out infinite;
                }
    
                .arrow:before,
                .arrow:after {
                background: #fff;
                content: '';
                display: block;
                height: 3px;
                position: absolute;
                top: 0;
                left: 0;
                width: 30px;
                }
    
                .arrow:before {
                -webkit-transform: rotate(45deg) translateX(-23%);
                        transform: rotate(45deg) translateX(-23%);
                -webkit-transform-origin: top left;
                        transform-origin: top left;
                }
    
                .arrow:after {
                -webkit-transform: rotate(-45deg) translateX(23%);
                        transform: rotate(-45deg) translateX(23%);
                -webkit-transform-origin: top right;
                        transform-origin: top right;
                }
    
                @-webkit-keyframes arrow-movement {
                0% {
                    opacity: 0;
                    top: 45%;
                }
                70% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
                }
    
                @keyframes arrow-movement {
                0% {
                    opacity: 0;
                    top: 45%;
                }
                70% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
              }
    
        }
    
        .DarkMask{
            position: absolute;
            width: 100%; 
            height: 100%;
            background-color: black;
            z-index: 101;
            opacity: 0.5;
        }
    
        .VideoTitle {
            font-size: 1em;
            span {
                margin-left: 10px;
                color:$Red;
                font-family: 'Raleway', sans-serif;
        
            }
        
            .Logo {
                position: relative;
                width: 180px;
                height: 30px;
                margin-top:10px;
                left: -4px;
                background-color: red;
                background: url(../../Images/logo.png);
                background-size:contain ;
                background-position: center center; 
                background-repeat: no-repeat;
                z-index: 100;

            }
        }
    }

  }