@import "../../styling/Colors.scss";

.Testimonial {
  position: relative;
  display: flex;
  height:450px;
  width:100%;
  background-size: cover;
  background-position: center center; 
  transition: 0.30s;
  
  .RightArrow {
    position: absolute;
    right:0;
    height: 100%;
    width: 12%;
    div {
      position: absolute;
      height: 50px;
      width: 50px;
      top:45%;
      right:100%;
      background-image: url(../../Images/Arrow.png);
      background-size: cover;
      filter: grayscale(100%);
      z-index: 0;
      opacity: 0;
    }
  }
  .RightArrow.active{
    div {
      filter: grayscale(0%);
      right:-20%;
      opacity: 1;
    }
  }
  .LeftArrow {
    position: absolute;
    height: 100%;
    width: 12%;
    z-index: 100;
    div {
      position: absolute;
      height: 50px;
      width: 50px;
      top:45%;
      left:100%;
      background-image: url(../../Images/Arrow.png);
      background-size: cover;
      transform: rotate(180deg);
      filter: grayscale(100%);
      z-index: 0;
      opacity: 0;

    }
  }

  .LeftArrow.active{
    div {
      filter: grayscale(0%);
      left:-20%;
      opacity: 1;
    }
  }

  .content1 {
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
  .Profile{
    position: relative;
    height: 40%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 150px;
      width: 150px;
      background: url("../../Images/Tertemonial/02.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-40%,-50%);
      top: 50%;
      left:45%;
      margin-top:10px;
    }
  }
  .ProfileComment{
    position: relative;
    height: 50%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;

    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
  .content2 {
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
    opacity: 1;
  .Profile{
    position: relative;
    height: 40%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 150px;
      width: 150px;
      background: url("../../Images/Tertemonial/01.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-40%,-50%);
      top: 50%;
      left:45%;
      margin-top:10px;

    }

  }
  .ProfileComment{
    position: relative;
    height: 50%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;

    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
  .content3 {
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
  .Profile{
    position: relative;
    height: 40%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 150px;
      width: 150px;
      background: url("../../Images/Tertemonial/03.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-40%,-50%);
      top: 50%;
      left:45%;
      margin-top:10px;
    }
  }
  .ProfileComment{
    position: relative;
    height: 50%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;

    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
  .content4 {
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
  .Profile{
    position: relative;
    height: 40%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 150px;
      width: 150px;
      background: url("../../Images/Tertemonial/04.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-40%,-50%);
      top: 50%;
      left:45%;
      margin-top:10px;
    }
  }
  .ProfileComment{
    position: relative;
    height: 50%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;

    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
  .content5 {
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
  .Profile{
    position: relative;
    height: 40%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 150px;
      width: 150px;
      background: url("../../Images/Tertemonial/05.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-40%,-50%);
      top: 50%;
      left:45%;
      margin-top:10px;
    }
  }
  .ProfileComment{
    position: relative;
    height: 50%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;

    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
  .content6 {
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
  .Profile{
    position: relative;
    height: 40%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 150px;
      width: 150px;
      background: url("../../Images/Tertemonial/06.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-40%,-50%);
      top: 50%;
      left:45%;
      margin-top:10px;
    }
  }
  .ProfileComment{
    position: relative;
    height: 50%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;

    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
  .content7 {
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
  .Profile{
    position: relative;
    height: 40%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 150px;
      width: 150px;
      background: url("../../Images/Tertemonial/07.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-40%,-50%);
      top: 50%;
      left:45%;
      margin-top:10px;
    }
  }
  .ProfileComment{
    position: relative;
    height: 50%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;

    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
  .content8 {
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
  .Profile{
    position: relative;
    height: 40%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 150px;
      width: 150px;
      background: url("../../Images/Tertemonial/08.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-40%,-50%);
      top: 50%;
      left:45%;
      margin-top:10px;
    }
  }
  .ProfileComment{
    position: relative;
    height: 50%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;

    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
  .content9 {
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
  .Profile{
    position: relative;
    height: 40%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 150px;
      width: 150px;
      background: url("../../Images/Tertemonial/09.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-40%,-50%);
      top: 50%;
      left:45%;
      margin-top:10px;
    }
  }
  .ProfileComment{
    position: relative;
    height: 50%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;

    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
}
@media only screen and (max-width: 800px) {
  .Testimonial {
    overflow: hidden;
    position: relative;
    display: flex;
    height:550px;
    width:100%;
    background-size: cover;
    background-position: center center; 
    transition: 0.30s;
    
    .RightArrow {
      position: absolute;
      right:0;
      height: 100%;
      width: 12%;
      div {
        position: absolute;
        height: 50px;
        width: 50px;
        top:45%;
        right:100%;
        background-image: url(../../Images/Arrow.png);
        background-size: cover;
        filter: grayscale(100%);
        z-index: 0;
        opacity: 0;
      }
    }
    .RightArrow.active{
      div {
        filter: grayscale(0%);
        right:-20%;
        opacity: 1;
      }
    }
    .LeftArrow {
      position: absolute;
      height: 100%;
      width: 12%;
      z-index: 100;
      div {
        position: absolute;
        height: 50px;
        width: 50px;
        top:45%;
        left:100%;
        background-image: url(../../Images/Arrow.png);
        background-size: cover;
        transform: rotate(180deg);
        filter: grayscale(100%);
        z-index: 0;
        opacity: 0;
  
      }
    }
  
    .LeftArrow.active{
      div {
        filter: grayscale(0%);
        left:-20%;
        opacity: 1;
      }
    }
  
    .content1 {
      overflow: hidden;
      position: absolute;
      height: 100%;
      width: 82%;
      z-index: 100;
      z-index: 0; 
      transform: translate(-50%,0);
      left:50%;
    .Profile{
      position: relative;
      height: 20%;
      width: 100%;
      .ProfilePic{
        position: absolute;
        height: 90px;
        width: 90px;
        background: url("../../Images/Tertemonial/02.jpg");
        background-size: cover;
        border-radius: 50% !important;
        border: 4px solid $Red;
        box-sizing: border-box;
        transform: translate(-50%,-50%);
        top: 50%;
        left:45%;
        margin-top:10px;
      }
    }
    .ProfileComment{
      overflow: hidden;
      position: relative;
      height: 80%;
      width: 100%;
      font-size: 1.1em;
      line-height: 1.5;
      vertical-align: middle;
      text-align: center;
      padding-top: 10px;
      margin-top:20px;
  
      p {
        margin: 0;
      }
      .ProfileName{
        position: relative;
        height: 100%;
        width: 100%;
        text-align: center;
        font-size: 1em;
        color: $LightGrey2;
        text-align: center;
      }
    }
  }
  .content2 {
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
    opacity: 1;
  .Profile{
    position: relative;
    height: 20%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 90px;
      width: 90px;
      background: url("../../Images/Tertemonial/01.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-50%,-50%);
      top: 40%;
      left:45%;
      margin-top:10px;
  
    }
  
  }
  .ProfileComment{
    position: relative;
    height: 80%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;
  
    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
  .content1Active{
    position: absolute;
    height: 100%;
    width: 76%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:-20%;
    opacity: 0;
  }
  .content2Active{
    position: absolute;
    height: 100%;
    width: 76%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:-20%;
    opacity: 0;
  }
  .content3 {
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
  .Profile{
    position: relative;
    height: 20%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 90px;
      width: 90px;
      background: url("../../Images/Tertemonial/03.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-50%,-50%);
      top: 50%;
      left:45%;
      margin-top:10px;
    }
  }
  .ProfileComment{
    position: relative;
    height: 62%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;

    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
  .content4 {
    position: absolute;
    height: 100%;
    width: 82%;
    z-index: 100;
    z-index: 0; 
    transform: translate(-50%,0);
    left:50%;
  .Profile{
    position: relative;
    height: 20%;
    width: 100%;
    .ProfilePic{
      position: absolute;
      height: 90px;
      width: 90px;
      background: url("../../Images/Tertemonial/04.jpg");
      background-size: cover;
      border-radius: 50% !important;
      border: 4px solid $Red;
      box-sizing: border-box;
      transform: translate(-50%,-50%);
      top: 50%;
      left:45%;
      margin-top:10px;
    }
  }
  .ProfileComment{
    position: relative;
    height: 50%;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
    padding-top: 10px;
    margin-top:20px;

    p {
      margin: 0;
    }
    .ProfileName{
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 1em;
      color: $LightGrey2;
      text-align: center;
    }
  }
  }
  }
}