@import "../../styling/Colors.scss";

* {
  border-radius: 0 !important;
  transition-duration: 0.5s;
  font-family:'Raleway', sans-serif;
}

.BusinessDescription{
  position: relative;
  height: auto;
  text-align: center;
  font-size: 1.2em;
  padding: 80px;
  color:$DarkGrey;
  z-index: 1;

}
.Line {
  position: absolute;
  height: 20px;
  width: 400px;
  left:-600px;
  top:90px;
  z-index: 100;
  .LineBody{
    position: absolute;
    height: 2px;
    width: 400px;
    background: $LightGrey2;
    background-image: linear-gradient(to right, $Transparent, $LightGrey2);
  }
  .LineCircle{
    position: absolute;
    right:0;
    height: 12px;
    width: 12px;
    background: $Red;
    border: 0 solid;
    border-radius: 50% !important;
    transform: translate(0%, -50%);
  }
}
.Lineactive{
  .Line{
  left:-328px;
  }
}

.OneByOne {
  height:250px;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/01.jpg);
  background-size: cover;
  background-position: center center; 
  }


  .TwoByTwo {
    height:500px;
    width:100%;
    background-color:lightslategrey;
    background-size: cover;
    background-position: center center; 
  }

  .paper {
    padding: 10px;
    text-align: center;
    color: grey;
  }

  .TopWeddingVideo {
    position: relative;
    height:800px;
    width:100%;
    background-position: center center; 
    transition: 5s;
    .TopWedding{
      position: absolute;
      height: 0%;
      width: 50px;
      right:0;
      z-index: 100;
      border-left : solid 2px $Red;
      background: rgba(0, 0, 0, 0.3);
    }
    div{
      position: absolute;
      color:white;
      height: 300px;
      width: 50px;
      bottom:0;
      p{
        position: absolute;
        display:block;
        -webkit-transform: rotate(-90deg); 
        -moz-transform: rotate(-90deg); 
        transform-origin: left top 0;
        font-size:1.3em;
        height: 50px;
        width: 240px;
        bottom:-50px;
        left:5px;
        margin: auto;
        text-align: center;
        line-height: 1.8em;
        
      }
    }
    video {
      filter: grayscale(100%);  
    }
  }

  .TopWeddingVideActive {

    transition: 0.30s;
    .TopWedding{
      height: 100%;
      width: 50px;
    }
    video {
      filter: grayscale(0%);  
    }
  }

  .RedCorners {
    position: relative;
    height:565px;
    width:100%;
    transition: 0.30s;
    .RedCorneresPicture{
      position: absolute;
      height: 100%;
      width: 100%;
      top:0;
      background: url("../../Images/10.JPG");
      background-size: cover;
      background-position: center center; 
      background-repeat: none;
      filter: grayscale(100%);
      z-index: 1;
    }


    .RedCornersInner{
      position: relative;
      height: 100%;
      width: 100%;
      z-index:2;

      .FrameTopLeft{
        height: 100px;
        width: 100px;
        position: absolute;
        left: 0%;
        top: 0%;
        border-left-style: solid;
        border-left-width: 0px;
        border-color: $Red;
        border-top-style: solid;
        border-top-width: 0px;
        border-color: $Red;

      }

      .FrameTopRight{
        height: 100px;
        width: 100px;
        position: absolute;
        right: 0;
        top: 0;
        border-right-style: solid;
        border-right-width: 0px;
        border-color: $Red;
        border-top-style: solid;
        border-top-width: 0px;
        border-color: $Red;
      }

      .FrameBottomRight{
        height: 100px;
        width: 100px;
        position: absolute;
        right: 0;
        bottom: 0;
        border-right-style: solid;
        border-right-width: 0px;
        border-color: $Red;
        border-bottom-style: solid;
        border-bottom-width: 0px;
        border-color: $Red;
      }

      .FrameBottomLeft{
        height: 100px;
        width: 100px;
        position: absolute;
        left: 0;
        bottom: 0;
        border-left-style: solid;
        border-left-width: 0px;
        border-color: $Red;
        border-bottom-style: solid;
        border-bottom-width: 0px;
        border-color: $Red;
      }
    }
    .active{
      .RedCornersInner{
        position: relative;
        height: 100%;
        width: 100%;
    
        .FrameTopLeft{
          height: 70px;
          width: 70px;
          position: absolute;
          left: 10%;
          top: 8%;
          border-left-style: solid;
          border-left-width: 8px;
          border-color: $Red;
          border-top-style: solid;
          border-top-width: 8px;
          border-color: $Red;
        }

        .FrameTopRight{
          height: 70px;
          width: 70px;
          position: absolute;
          right: 10%;
          top: 8%;
          border-right-style: solid;
          border-right-width: 8px;
          border-color: $Red;
          border-top-style: solid;
          border-top-width: 8px;
          border-color: $Red;
        }

        .FrameBottomRight{
          height: 70px;
          width: 70px;
          position: absolute;
          right: 10%;
          bottom: 8%;
          border-right-style: solid;
          border-right-width: 8px;
          border-color: $Red;
          border-bottom-style: solid;
          border-bottom-width: 8px;
          border-color: $Red;
        }

        .FrameBottomLeft{
          height: 70px;
          width: 70px;
          position: absolute;
          left: 10%;
          bottom: 8%;
          border-left-style: solid;
          border-left-width: 8px;
          border-color: $Red;
          border-bottom-style: solid;
          border-bottom-width: 8px;
          border-color: $Red;
        }

      }
    }

  }
  .RedCorners:hover{
    .RedCorneresPicture{
      filter: grayscale(0%);
    }
  }

  .Services {
    font-family: 'Alcubierre','typeface-roboto', Arial, Helvetica, sans-serif;
    position: relative;
    height:auto;
    width:90%;
    background-size: cover;
    background-position: center center; 
    transition: 0.30s;
    z-index: 1;

    h2{
      position: relative;
      top: 30px;
      left:10%;
      color:$Red;
      font-size:1.6em;
      word-wrap: break-word;

    }
    h4{
      position: relative;
      top: 20px;
      left:10%;
      color:$LightGrey3;
      width: 60%;
      font-size:1.2em;
    }
    div {
      display: flex;
      top:-30px;
      

      .ServicesContent{
        position: relative;
        display: block;

        h4 {
          position: relative;
          margin-top: 45px;
          color:$LightGrey;
        }
      }
      .ServicesStyling{
        position: absolute;
        height: 350px;
        width: 10%;
        top:0;
        left:0;

          .Line1 {
            position: absolute;
            height: 20px;
            width: 400px;
            right:320px;
            top:22%;
            z-index: 100;
            .LineBody{
              position: absolute;
              height: 2px;
              width: 400px;
              background: $LightGrey2;
              background-image: linear-gradient(to right, $Transparent, $LightGrey2);
            }
            .LineCircle{
              position: absolute;
              right:0;
              height: 9px;
              width: 9px;
              background: $Red;
              border: 0 solid;
              border-radius: 50% !important;
              transform: translate(0%, -50%);
            }
          }
         
          .ServicesLineActive{
            right:15px;
          }
      }
    }
  }
  
  .LongPic {
    position: relative;
    height:950px;
    width:100%;
    background-color:red;

    .LongPicRecordingDot{
      position: absolute;
      top:5%;
      right:7%;
      height: 25px;
      width: 25px;
      background:$Red!important;
      border-radius: 50%!important;
      z-index: 100;
      animation-name: Blinking;
      animation-duration: 2s;
      animation-iteration-count: infinite;
        }    
    .LongPicContent {
      filter: grayscale(100%);
      height: 100%;
    }
  }
  .LongPic:hover {
    .LongPicContent {
      filter: grayscale(0%);
    };
  }

  @keyframes Blinking {
    0% {opacity: 1;}
    25% {opacity: 0;}
    50% {opacity: 0;}
    100% {opacity: 1;}
  }
  .Media {
    position: relative;
    display: flex;
    height:390px;
    width:100%;
    z-index: 1;
    .SocialMedia{
      position: relative;
      color: $DarkGrey;
      height: 100%;
      width: 50%;
      font-size:2em;
      p{
        position: relative;
        top:37%;
      
      }
    }
    .Icons {
      position: absolute;
      top:0%;
      right:15%;
      height: 100%;
      width: 70px;

        .FB{
          position: absolute;
          height: 70px;
          width: 70px;
          background: url("../../Images/FB.png");
          background-size: cover;
          &:hover {
            transform: scale(0.8, 0.8);
         }
        }
        .IG{
          position: absolute;
          height: 70px;
          width: 70px;
          background: url("../../Images/IG.png");
          background-size: cover;
          top:25%;
          &:hover {
            transform: scale(0.8, 0.8);
         }
        }
        .Vimeo{
          position: absolute;
          height: 70px;
          width: 70px;
          background: url("../../Images/Vimeo.png");
          background-size: cover;
          top:50%;
          &:hover {
            transform: scale(0.8, 0.8);
         }
        }
        .YouTube{
          position: absolute;
          height: 70px;
          width: 70px;
          background: url("../../Images/YouTube.png");
          background-size: cover;
          top:75%;
          &:hover {
            transform: scale(0.8, 0.8);
         }
        }
    }
    .LineContainer{
      position: relative;
      height: 100%;
      width: 115px;
      .Line5 {
        position: absolute;
        height: 20px;
        width: 400px;
        left:-550px;
        top:50.5%;
        z-index: 100;
        .LineBody{
          position: absolute;
          height: 2px;
          width: 400px;
          background: $LightGrey2;
          background-image: linear-gradient(to right, $Transparent, $LightGrey2);
        }
        .LineCircle{
          position: absolute;
          right:0;
          height: 9px;
          width: 9px;
          background: $Red;
          border: 0 solid;
          border-radius: 50% !important;
          transform: translate(0%, -50%);
        }
      }
      .Line5Active {
        left:-300px;
      }
    }

  }
  
  .Contacts {
    position: relative;
    display: block;
    height:305px;
    width:100%;
    font-family: 'Alcubierre','typeface-roboto', Arial, Helvetica, sans-serif;
    top:60px;
    h1{
      position: relative;
      left:15%;
      top:10px;
      color:$Red;
    }

    .ContactsContent{
      position: relative;
      display: flex;
      height: 100%;
      width: auto;
      .ContactsStyling{
        position: relative;
        height: 100%;
        width: 200px;
        z-index: 95;
        .Line1 {
          position: absolute;
          height: 20px;
          width: 400px;
          right:320px;
          top:16%;
          z-index: 110;

          .LineBody{
            position: absolute;
            height: 2px;
            width: 400px;
            background: $LightGrey2;
            background-image: linear-gradient(to right, $Transparent, $LightGrey2);
          }
          .LineCircle{
            position: absolute;
            right:0;
            height: 9px;
            width: 9px;
            background: $Red;
            border: 0 solid;
            border-radius: 50% !important;
            transform: translate(0%, -50%);
          }
        }
        .Line2 {
          position: absolute;
          height: 20px;
          width: 400px;
          right:320px;
          top:35%;
          z-index: 100;
          .LineBody{
            position: absolute;
            height: 2px;
            width: 400px;
            background: $LightGrey2;
            background-image: linear-gradient(to right, $Transparent, $LightGrey2);
          }
          .LineCircle{
            position: absolute;
            right:0;
            height: 9px;
            width: 9px;
            background: $Red;
            border: 0 solid;
            border-radius: 50% !important;
            transform: translate(0%, -50%);
          }
        }
        .Line3 {
          position: absolute;
          height: 20px;
          width: 400px;
          right:320px;
          top:55%;
          z-index: 100;
          .LineBody{
            position: absolute;
            height: 2px;
            width: 400px;
            background: $LightGrey2;
            background-image: linear-gradient(to right, $Transparent, $LightGrey2);
          }
          .LineCircle{
            position: absolute;
            right:0;
            height: 9px;
            width: 9px;
            background: $Red;
            border: 0 solid;
            border-radius: 50% !important;
            transform: translate(0%, -50%);
          }
        }
        .ServicesLineActive{
          right:15px;

        }
      }
      .ContactsDetails{
        position: relative;
        height: 100%;
        width: 100%;
        font-size: 1.6em;
        color:$DarkGrey;
        padding-top:10px;
        div {
          margin-top: 22px; 

          Button{
            font-size:1em;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  .Footer {
    color:$LightGrey;
    text-align: center;
  }

  .ContactsPage {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: blue;
    z-index: 110;
    top:0;
    bottom: 0;
    left:0;
    overflow: hidden;

  }

  @media only screen and (max-width: 800px) {
    * {
      overflow-x:hidden;
      
      Grid {
        overflow: hidden!important;
        filter: grayscale(0%);
      }
    }

    .Line {

      left:-600px;
    }
    .Lineactive{
      .Line{
      left:-600px;
      }
    }
    .BusinessDescription{
      padding: 20px;  
    }
    .Services {
      font-family: 'Alcubierre','typeface-roboto', Arial, Helvetica, sans-serif;
      position: relative;
      height:400px;
      width:100%;
      background-size: cover;
      background-position: center center; 
      transition: 0.30s;
      z-index: 1;
      overflow: hidden;
      
  
      h2{
        position: relative;
        top: 10px;
        left:5%;
        color:$Red;
        font-size:1.8em;
        width: 100%;
  
      }
      h4{
        position: relative;
        top: 0px;
        left:5%;
        color:$LightGrey3;
        width: 90%;
        font-size:1.0em;
      }
      div {
        display: flex;
        top:-40px;
        
  
        .ServicesContent{
          position: relative;
          display: block;
  
          h4 {
            position: relative;
            margin-top: 45px;
            color:$LightGrey;
          }
        }
        .ServicesStyling{
          position: absolute;
          height: 350px;
          width: 00px;
          top:0;

  
            .Line1 {
              position: absolute;
              height: 20px;
              width: 400px;
              right:320px;
              top:22%;
              z-index: 100;
              .LineBody{
                position: absolute;
                height: 2px;
                width: 400px;
                background: $LightGrey2;
                background-image: linear-gradient(to right, $Transparent, $LightGrey2);
              }
              .LineCircle{
                position: absolute;
                right:0;
                height: 9px;
                width: 9px;
                background: $Red;
                border: 0 solid;
                border-radius: 50% !important;
                transform: translate(0%, -50%);
              }
            }
           
            .ServicesLineActive{
              right:15px;
            }
        }
      }
    }
    
    .Media {
      position: relative;
      display: flex;
      height:390px;
      width:100%;
      z-index: 1;
      .SocialMedia{
        position: relative;
        color: $DarkGrey;
        height: 100%;
        width: 50%;
        font-size:2em;
        p{
          position: relative;
          top:33%;
        
        }
      }
      .Icons {
        position: absolute;
        top:10%;
        right:15%;
        height: 80%;
        width: 70px;
          .FB{
            position: absolute;
            height: 70px;
            width: 70px;
            background: url("../../Images/FB.png");
            background-size: cover;
          }
          .IG{
            position: absolute;
            height: 70px;
            width: 70px;
            background: url("../../Images/IG.png");
            background-size: cover;
            top:50%;
            transform: translate(0%, -50%);
  
          }
          .Vimeo{
            position: absolute;
            height: 70px;
            width: 70px;
            background: url("../../Images/Vimeo.png");
            background-size: cover;
            bottom:0;
          }
      }
      .LineContainer{
        position: relative;
        height: 100%;
        width: 45px;
        .Line5 {
          left:-550px;
          .LineBody{
            position: absolute;
            height: 2px;
            width: 400px;
            background: $LightGrey2;
            background-image: linear-gradient(to right, $Transparent, $LightGrey2);
          }
          .LineCircle{
            position: absolute;
            right:0;
            height: 9px;
            width: 9px;
            background: $Red;
            border: 0 solid;
            border-radius: 50% !important;
            transform: translate(0%, -50%);
          }
        }

      }
  
    }

    .Contacts {
      overflow: hidden;
      position: relative;
      display: block;
      height:250px;
      width:100%;
      font-family: 'Alcubierre','typeface-roboto', Arial, Helvetica, sans-serif;
      z-index: 1;
      overflow: hidden!important;
      top:-0px;
      
      h1{
        position: relative;
        left:20px;
        top:10px;
        color:$Red;
  
      }
  
      .ContactsContent{
        overflow: hidden!important;
        height:140px;

        .ContactsStyling{
          position: relative;
          height: 100%;
          width: 20px;
          .Line1 {
            position: absolute;
            height: 20px;
            width: 400px;
            right:320px;
            top:18%;
            z-index: 100;
            .LineBody{
              position: absolute;
              height: 2px;
              width: 400px;
              background: $LightGrey2;
              background-image: linear-gradient(to right, $Transparent, $LightGrey2);
            }
            .LineCircle{
              position: absolute;
              right:0;
              height: 9px;
              width: 9px;
              background: $Red;
              border: 0 solid;
              border-radius: 50% !important;
              transform: translate(0%, -50%);
            }
          }
          .Line2 {
            position: absolute;
            height: 20px;
            width: 400px;
            right:320px;
            top:34%;
            z-index: 100;
            .LineBody{
              position: absolute;
              height: 2px;
              width: 400px;
              background: $LightGrey2;
              background-image: linear-gradient(to right, $Transparent, $LightGrey2);
            }
            .LineCircle{
              position: absolute;
              right:0;
              height: 9px;
              width: 9px;
              background: $Red;
              border: 0 solid;
              border-radius: 50% !important;
              transform: translate(0%, -50%);
            }
          }
          .Line3 {
            position: absolute;
            height: 20px;
            width: 400px;
            right:320px;
            top:54%;
            z-index: 100;
            .LineBody{
              position: absolute;
              height: 2px;
              width: 400px;
              background: $LightGrey2;
              background-image: linear-gradient(to right, $Transparent, $LightGrey2);
            }
            .LineCircle{
              position: absolute;
              right:0;
              height: 9px;
              width: 9px;
              background: $Red;
              border: 0 solid;
              border-radius: 50% !important;
              transform: translate(0%, -50%);
            }
          }
          .ServicesLineActive{
            right:320px;
          }
        }
        .ContactsDetails{
          position: relative;
          height: 180px;
          width: 100%;
          font-size: 1.3em;
          color:$DarkGrey;
          padding-top:10px;
          overflow: hidden!important;


          div {
            margin-top: 10px; 
            overflow: hidden!important;
            Button{
              font-size:1em;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }

    .LongPic {
      position: relative;
      height:900px;
      width:100%;
      background-color:red;
  
      .LongPicRecordingDot{
        position: absolute;
        top:5%;
        right:7%;
        height: 25px;
        width: 25px;
        background:$Red!important;
        border-radius: 50%!important;
        z-index: 100;
        animation-name: Blinking;
        animation-duration: 2s;
        animation-iteration-count: infinite;
          }    
      .LongPicContent {
        filter: grayscale(0%);
      }
    }
    .LongPic:hover {
      .LongPicContent {
        filter: grayscale(0%);
      };
    }
    .TopWeddingVideo {
      video {
        filter: grayscale(0%);  
      }
    }
    .RedCorners {
      .RedCorneresPicture{
        filter: grayscale(0%);
      }
    }
  }
