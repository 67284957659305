@import "../../styling/Colors.scss";

.servicesPageContainer{
  position: relative;
  width: 100vw;
  height: 100vh;

  video {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -1;

  }
  .DarkMaskCover {
    position: fixed;
    background:rgba(0, 0, 0, 0.68);
    height: 100vh;
    width: 100vw;
  }
  p {
    font-size: 1.3em;
    color:white;
  }
  h2 {
    font-size: 1.6em;
    color:$Red;
  }
  h3 {
    font-size: 1.2em;
    color:$Red;
  }
  .ServiceSection1{
    position: absolute;
    left:-1000px;
    top:200px;
    height: auto;
    width: 45%;
    padding:40px;
    opacity: 0;
  }
  .ServiceSection1Active{
    left:120px;
    opacity: 1;
  }


  .ServiceSection2{
    position: absolute;
    right:-1000px;
    top:750px;
    height: auto;
    width: 45%;
    padding:40px;
    text-align: right;
    opacity: 0;
  }
  .ServiceSection2Active{
    right:160px;
    opacity: 1;
  }
  .ServiceSection3{
    position: absolute;
    left:-1000px;
    top:1150px;
    height: auto;
    width: 70%;
    padding:40px;
    opacity: 0;
    li{
      color:white;
      font-size:1.3em;
      margin-top:12px;
      list-style: none;
    }
  }
  .ServiceSection3Active{
    left:140px;
    opacity: 1;
  }
  .ServiceSection4{
    position: absolute;
    right:-1000px;
    top:1700px;
    height: auto;
    width: 60%;
    padding:40px;
    text-align: right;
    opacity: 0;
    margin-bottom:200px;
      li{
        color:white;
        font-size:1.3em;
        margin-top:12px;
        list-style: none;
        margin-right:30px;
      }
    
  }
  .ServiceSection4Active{
    right:160px;
    opacity: 1;
  }
}
.Arrows {
  position: fixed;
  height: 300px;
  width: 300px;
  top:90%;
  left: 50%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
-webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  .arrow {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
  }

  .arrow-1 {
    -webkit-animation: arrow-movement 2s ease-in-out infinite;
            animation: arrow-movement 2s ease-in-out infinite;
  }

  .arrow-2 {
    -webkit-animation: arrow-movement 2s 1s ease-in-out infinite;
            animation: arrow-movement 2s 1s ease-in-out infinite;
  }

  .arrow:before,
  .arrow:after {
    background: #fff;
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    z-index: 200;
  }

  .arrow:before {
    -webkit-transform: rotate(45deg) translateX(-23%);
            transform: rotate(45deg) translateX(-23%);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }

  .arrow:after {
    -webkit-transform: rotate(-45deg) translateX(23%);
            transform: rotate(-45deg) translateX(23%);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }

  @-webkit-keyframes arrow-movement {
    0% {
      opacity: 0;
      top: 45%;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes arrow-movement {
    0% {
      opacity: 0;
      top: 45%;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
.ArrowsActive {
opacity: 1;
}

@media only screen and (max-width: 800px) {
  .servicesPageContainer{
  position: relative;
  width: 100vw;
  height: 100vh;

  video {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -1;

  }
  .DarkMaskCover {
    position: fixed;
    background:rgba(0, 0, 0, 0.68);
    height: 100vh;
    width: 100vw;
  }
  p {
    font-size: 1em;
    color:white;
  }
  h2 {
    font-size: 1.1em;
    color:$Red;
  }
  h3 {
    font-size: 1.2em;
    color:$Red;
  }
 
  .ServiceSection1{
    position: absolute;
    left:0px;
    top:40px;
    height: auto;
    width: 80%;
    padding:40px;
    opacity: 1;
  }
  .ServiceSection1Active{
    position: absolute;
    left:0px;
    top:40px;
    height: auto;
    width: 80%;
    padding:40px;
    opacity: 1;
  }
  .ServiceSection2{
    position: absolute;
    right:0px;
    top:500px;
    height: auto;
    width: 80%;
    padding:40px;
    text-align: left;
    opacity: 1;
  }
  .ServiceSection2Active{
    position: absolute;
    right:0px;
    top:500px;
    height: auto;
    width: 80%;
    padding:40px;
    text-align: left;
    opacity: 1;
  }
  .ServiceSection3{
    position: absolute;
    left:0px;
    top:800px;
    height: auto;
    width: 80%;
    padding:40px;
    opacity: 1;
    li{
      color:white;
      font-size:1em;
      margin-top:12px;
      list-style: none;
    }
  }
  .ServiceSection3Active{
    position: absolute;
    left:0px;
    top:800px;
    height: auto;
    width: 80%;
    padding:40px;
    opacity: 1;
    li{
      color:white;
      font-size:1em;
      margin-top:12px;
      list-style: none;
    }
  }
  .ServiceSection4{
    position: absolute;
    right:0px;
    top:1380px;
    height: auto;
    width: 80%;
    padding:40px;
    text-align: left;
    opacity: 1;
    margin-bottom:200px;
      li{
        color:white;
        font-size:1em;
        margin-top:12px;
        list-style: none;
        margin-right:30px;
      }
    
  }
  .ServiceSection4Active{
    position: absolute;
    right:0px;
    top:1380px;
    height: auto;
    width: 80%;
    padding:40px;
    text-align: left;
    opacity: 1;
    margin-bottom:200px;
      li{
        color:white;
        font-size:1em;
        margin-top:12px;
        list-style: none;
        margin-right:30px;
      }
  }
}
.Arrows {
  position: fixed;
  height: 300px;
  width: 300px;
  top:20%;
  left: 50%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
-webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  z-index: 200;
  .arrow {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    background:green;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
  }

  .arrow-1 {
    -webkit-animation: arrow-movement 2s ease-in-out infinite;
            animation: arrow-movement 2s ease-in-out infinite;
  }

  .arrow-2 {
    -webkit-animation: arrow-movement 2s 1s ease-in-out infinite;
            animation: arrow-movement 2s 1s ease-in-out infinite;
  }

  .arrow:before,
  .arrow:after {
    background: #fff;
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    z-index: 200;
  }

  .arrow:before {
    -webkit-transform: rotate(45deg) translateX(-23%);
            transform: rotate(45deg) translateX(-23%);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }

  .arrow:after {
    -webkit-transform: rotate(-45deg) translateX(23%);
            transform: rotate(-45deg) translateX(23%);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }

  @-webkit-keyframes arrow-movement {
    0% {
      opacity: 0;
      top: 45%;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes arrow-movement {
    0% {
      opacity: 0;
      top: 45%;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
.ArrowsActive {
opacity: 0;
}
}
