.GridContainer {
    background-color: none;
    width: 85%;
    height: auto;
    margin: auto;
    padding: 0;
    z-index: 99;
    .WhiteFade{
        position: fixed;
        height: 100vh;
        width: 8%;
        background-image: linear-gradient(to right, white , rgba(255, 255, 255, 0.76) , rgba(255, 255, 255, 0));
        left:0;
        top:0;
        z-index: 98;
    }
};