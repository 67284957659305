@import "../../styling/Colors.scss";

.RedDot {
    height: 8px;
    width: 8px;
    background-color:$Red;
    border-radius: 50% !important;
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;

}