
.Grid {
    background-color: Grey;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;

};
.RedDoteVideo{
  background-image: url(../../Images/25.jpg);
  height:100%;
  width:100%;
  background-color:lightslategrey;
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  overflow: hidden;

}

.RedDoteVideo:hover{
  filter: grayscale(0%);
}

.TwoOneVideo{
  background-image: url(../../Images/23.jpg);
  height:100%;
  width:100%;
  background-color:lightslategrey;
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  overflow: hidden;

}

.TwoOneVideo:hover{
  filter: grayscale(0%);
}

.OneTwoVideo{
  background-image: url(../../Images/24.jpg);
  height:100%;
  width:100%;
  background-color:lightslategrey;
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  overflow: hidden;

}

.OneTwoVideo:hover{
  filter: grayscale(0%);
}
.OneByOne1 {
  height:300px;;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/09.JPG);
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  overflow: hidden;

  }
  .OneByOne1:hover {
    filter: grayscale(0%);
    }

  .OneByOne2 {
    height:300px;
    width:100%;
    background-color:lightslategrey;
    background-image: url(../../Images/02.JPG);
    background-size: cover;
    background-position: center center;
    filter: grayscale(100%);
    transition: 0.30s;
    overflow: hidden;

    }
    .OneByOne2:hover {
      filter: grayscale(0%);
      }
  .OneByOne3 {
    height:300px;
    width:100%;
    background-color:lightslategrey;
    background-image: url(../../Images/12.jpg);
    background-size: cover;
    background-position: center center;
    filter: grayscale(100%);
    transition: 0.30s;
    overflow: hidden;

    
    }
    .OneByOne3:hover {
      filter: grayscale(0%);
      }
.OneByOne4 {
  height:300px;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/17.JPG);
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  overflow: hidden;

  }
.OneByOne4:hover {
  filter: grayscale(0%);
  }

  .OneByOne5 {
    height:300px;
    width:100%;
    background-color:lightslategrey;
    background-image: url(../../Images/14.JPG);
    background-size: cover;
    background-position: center center;
    filter: grayscale(100%);
    transition: 0.30s;
    overflow: hidden;

    }
    .OneByOne5:hover {
      filter: grayscale(0%);
      }

.OneByOne6 {
  height:300px;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/05.JPG);
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  overflow: hidden;

  }
  .OneByOne6:hover {
    filter: grayscale(0%);
    }

.OneByOne7 {
  height:300px;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/06.JPG);
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  overflow: hidden;

  }
  .OneByOne7:hover {
    filter: grayscale(0%);
    }

.OneByOne8 {
  height:300px;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/15.JPG);
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  overflow: hidden;

  }
  .OneByOne8:hover {
    filter: grayscale(0%);
    }
.OneByOne9 {
  height:300px;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/18.JPG);
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  overflow: hidden;

  }

.OneByOne9:hover {
  filter: grayscale(0%);
  }

  .OneByOne10 {
    height:300px;
    width:100%;
    background-color:lightslategrey;
    background-image: url(../../Images/19.JPG);
    background-size: cover;
    background-position: center center;
    filter: grayscale(100%);
    transition: 0.30s;
    overflow: hidden;

    }
    .OneByOne10:hover {
      filter: grayscale(0%);
      }

.OneByOne11 {
  height:300px;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/21.JPG);
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  }
  .OneByOne11:hover {
    filter: grayscale(0%);
    }

.OneByOne12 {
  height:300px;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/22.JPG);
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  }
  .OneByOne12:hover {
    filter: grayscale(0%);
    }
.OneByOne {
  height:150px;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/01.jpg);
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  }
  .OneByOne:hover {
    filter: grayscale(0%);
    }


.TwoByTwo {
  height:300px;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/01.jpg);
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
  overflow: hidden;
}
.TwoByTwo:hover {
  filter: grayscale(0%);
  }

.TwoByTwo1 {
  height:600px;
  width:100%;
  background-color:lightslategrey;
  background-image: url(../../Images/16.JPG);
  background-size: cover;
  background-position: center center;
  filter: grayscale(100%);
  transition: 0.30s;
    overflow: hidden;

}
.TwoByTwo1:hover {
  filter: grayscale(0%);
  }

.TwoByTwo2 {
height:500px;
width:100%;
background-color:lightslategrey;
background-image: url(../../Images/20.JPG);
background-size: cover;
background-position: center center;
filter: grayscale(100%);
transition: 0.30s;
overflow: hidden;

}
.TwoByTwo2:hover {
filter: grayscale(0%);
}

@media only screen and (max-width: 800px) {

.RedDoteVideo{

  filter: grayscale(0%);
}

.RedDoteVideo:hover{
  filter: grayscale(0%);
}

.TwoOneVideo{
  filter: grayscale(10%);
}

.TwoOneVideo:hover{
  filter: grayscale(0%);
}

.OneTwoVideo{

  filter: grayscale(0%);
}

.OneTwoVideo:hover{
  filter: grayscale(0%);
}
.OneByOne1 {

  filter: grayscale(0%);
  }
  .OneByOne1:hover {
    filter: grayscale(0%);
    }

  .OneByOne2 {
    filter: grayscale(0%);
    }
    .OneByOne2:hover {
      filter: grayscale(0%);
      }
  .OneByOne3 {
    filter: grayscale(0%);
    }
    .OneByOne3:hover {
      filter: grayscale(0%);
      }
.OneByOne4 {
  filter: grayscale(0%);
  }
.OneByOne4:hover {
  filter: grayscale(0%);
  }

  .OneByOne5 {
    filter: grayscale(0%);
    }
    .OneByOne5:hover {
      filter: grayscale(0%);
      }

.OneByOne6 {
  filter: grayscale(0%);
  }
  .OneByOne6:hover {
    filter: grayscale(0%);
    }

.OneByOne7 {
  filter: grayscale(0%);
  }
  .OneByOne7:hover {
    filter: grayscale(0%);
    }

.OneByOne8 {

  filter: grayscale(0%);
  }
  .OneByOne8:hover {
    filter: grayscale(0%);
    }
.OneByOne9 {
  filter: grayscale(0%);
  }

.OneByOne9:hover {
  filter: grayscale(0%);
  }

  .OneByOne10 {
    filter: grayscale(0%);
    }
    .OneByOne10:hover {
      filter: grayscale(0%);
      }

.OneByOne11 {
  filter: grayscale(0%);
  }
  .OneByOne11:hover {
    filter: grayscale(0%);
    }

.OneByOne12 {
  filter: grayscale(0%);
  }
  .OneByOne12:hover {
    filter: grayscale(0%);
    }
.OneByOne {
  filter: grayscale(0%);
  }
  .OneByOne:hover {
    filter: grayscale(0%);
    }


.TwoByTwo {
  filter: grayscale(0%);
}
.TwoByTwo:hover {
  filter: grayscale(0%);
  }

.TwoByTwo1 {
  filter: grayscale(0%);
}
.TwoByTwo1:hover {
  filter: grayscale(0%);
  }

.TwoByTwo2 {
filter: grayscale(0%);
}
.TwoByTwo2:hover {
filter: grayscale(0%);
}
}