@import "../../styling/Colors.scss";

.VideosStyleContainer {
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap; 
  justify-content:space-between;
  padding: 30px;
  padding-left:40px;
  padding-top:30px;
  background: black;
}
.VideosStyle {
  position: relative;
  display: inline-block;
  width: 24%;
  margin: 5px;
  padding-top:0px;
  div{
    iframe {
      height: 255px;
      width: 100%;
      padding-bottom: 2px;
    }
  }
}
.VideosStyle2VideosGrid {
  position: relative;
  display: inline-block;
  width: 49%;
  margin: 5px;
  padding-top:0px;
  div{
    iframe {
      height: 450px;
      width: 100%;
      padding-bottom: 2px;
    }
  }
}

.VideosStyle3VideosGrid {
  position: relative;
  display: inline-block;
  width: 32%;
  margin: 5px;
  padding-top:0px;
  div{
    iframe {
      height: 450px;
      width: 100%;
      padding-bottom: 2px;
    }
  }
}

.LogoInVideosPage {
  position: absolute;
  right:0;
  top:15px;
  height: 40px;
  width: 320px;
  z-index: 100;
  background-size: cover;

}
@media only screen and (max-width: 800px) {
  .VideosStyleContainer {
    position: relative;
    display: flex;
    flex-wrap: wrap; 
    justify-content:space-evenly;
    padding: 10px;
    padding-left:10px;

  }
  .VideosStyle, .VideosStyle2VideosGrid, .VideosStyle3VideosGrid  {
    position: relative;
    display: inline-block;
    width: 95%;
    height: 100%;
    margin: 5px;
    div{
      iframe {
        height: 190px;
        width: 100%;
      }
    }
  }
  .LogoInVideosPage {
    position: absolute;
    right:0;
    top:15px;
    height: 20px;
    width: 160px;
    z-index: 100;
    background-size: cover;
    opacity: 0;
  
  }
}