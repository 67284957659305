.DarkSideBar {
  position: fixed;
  width: 40px;
  height: 100vh;
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(8,8,8,1) 11%, rgba(255,255,255,0) 100%);
  z-index: 50;
  opacity: 1;
}
.GallaryPhotos{
  background-size: cover;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

@media only screen and (max-width: 800px) {
  .DarkSideBar {
    position: fixed;
    width: 40px;
    height: 100vh;
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(8,8,8,1) 11%, rgba(255,255,255,0) 100%);
    z-index: 50;
    opacity: 0;
  }
}