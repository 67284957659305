@import "../../styling/Colors.scss";

.LogoStyle {
    position: absolute;
    height: 70px;
    width: 280px;
    background:red;
    background: url("./../../Images/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 100;
    margin: 30px;
    margin-top: 0; 

}