$DarkGrey: #1A1919;
$LightGrey: #363534;
$Red:#FA2A00;
$LightGrey2:#B2B0AD;
$LightGrey3:rgb(160, 160, 160);
$Transparent: rgba(255,0,0,0);

@font-face {
    font-family: 'Alcubierre';
    font-style: "Regular";
    font-weight: 400;
    src: local('../styling/Alcubierre.otf'); /* IE9 Compat Modes */

  }
